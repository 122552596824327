<template>
  <section>
    <div>
      <slot name="filter"></slot>
      <h5 class="text-danger" v-if="getErrors.listings && getErrors.listings.length">
        {{ getErrors.listings }}
      </h5>
      <Spinner v-else-if="getLoadings.listings" />
      <div v-show="!getLoadings.listings && !getErrors.listings">
        <slot>
          <div class="row mx-0">
            <div class="col-lg-4 col-sm-6 col-12 pr-0 pr-sm-2 pl-0" v-for="listing in listings" :key="listing.id">
              <Listing :listing="listing" :externalClass="['recent-listing']"
                :listingLink="'/listings/' + listing.id" />
            </div>
          </div>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
import Listing from "./Listing.vue";
import { mapGetters } from "vuex";
import Spinner from "../../ui/Spinner.vue";
export default {
  components: { Listing, Spinner },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  props: {
    listings: {
      type: Array,
      required: true,
    },
    smContainer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__listings {
    &__heading {
      @include font(2.25rem, $primary, 700);
      color: $primary !important;
    }
  }
}
</style>
