var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-3"},[_c('h1',[_vm._v(_vm._s(_vm.$t("listings")))])]),_c('div',{staticClass:"d-sm-none"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"input-group mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control bg-white",attrs:{"type":"text","placeholder":_vm.$t('Search for listings...'),"aria-label":"Username","aria-describedby":"basic-addon1"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_c('div',{staticClass:"ml-3"},[_c('button',{staticClass:"btn btn-white text-primary",on:{"click":function($event){return _vm.$bvModal.show('mobile-filter')}}},[_c('i',{staticClass:"fa-solid fa-sliders"})])])])]),_c('MobileFilter',{on:{"on-filter":_vm.onFilter}}),_c('Listings',{attrs:{"smContainer":false,"listings":_vm.listings.data},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('div',{staticClass:"d-none d-sm-block"},[_c('div',{staticClass:"listings__filters d-flex align-items-center justify-content-md-between"},[_c('div',{staticClass:"d-flex align-items-center align-self-end"},[_c('button',{staticClass:"btn p-0 text-primary listings__filters__Btn position-relative",class:{
                'text-gray3': _vm.activeListing !== 'suitable',
                active: _vm.activeListing === 'suitable',
              },on:{"click":function($event){return _vm.onListingChange('suitable')}}},[_vm._v(" "+_vm._s(_vm.$t("Suitable listing"))+" ")]),_c('button',{staticClass:"btn p-0 text-primary ml-3 listings__filters__Btn position-relative",class:{
                'text-gray3': _vm.activeListing !== 'nonSuitable',
                active: _vm.activeListing === 'nonSuitable',
              },on:{"click":function($event){return _vm.onListingChange('nonSuitable')}}},[_vm._v(" "+_vm._s(_vm.$t("Non-suitable listing"))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('BButton',{attrs:{"variant":"outline-primary mr-2"},on:{"click":function($event){return _vm.$router.push('listings/map')}}},[_vm._v(" "+_vm._s(_vm.$t("mapview")))])],1),_c('label',{staticClass:"listings__filters__searchbox border mt-md-0 mb-0",attrs:{"for":"search"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"listings__filters__searchbox__input",attrs:{"type":"text","placeholder":_vm.$t('listingsKey.filters.searchplaceholder')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('Magnify')],1)])])])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-4 z-index-100 d-none d-sm-block"},[_c('ListingFilters',{staticClass:"position-sticky",on:{"on-filter":_vm.onFilter}})],1),(!_vm.listings.data.length)?_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"listings__empty"},[_vm._v(" "+_vm._s(_vm.$t("No listings available"))+" ")])]):_vm._e()]),(_vm.listings.data.length)?[_c('div',{staticClass:"row"},_vm._l((_vm.listings.data),function(listing){return _c('div',{key:listing.id,staticClass:"col-lg-4 col-sm-6 col-12"},[_c('Listing',{attrs:{"listing":listing,"externalClass":['recent-listing'],"listingLink":listing.reacted ? '/listings/saved' : `/listings/${listing.slug}`}})],1)}),0),_c('b-pagination',{attrs:{"total-rows":_vm.listings.total,"per-page":_vm.limit,"aria-controls":"my-table","align":"left"},on:{"change":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]:_vm._e()],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-white",attrs:{"id":"basic-addon1"}},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])])
}]

export { render, staticRenderFns }