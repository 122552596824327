<template>
  <div class="container">
    <div class="mb-3">
      <h1>{{ $t("listings") }}</h1>
    </div>
    <div class="d-sm-none">
      <div class="d-flex">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white" id="basic-addon1">
              <i class="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
          <input
            type="text"
            v-model="search"
            class="form-control bg-white"
            :placeholder="$t('Search for listings...')"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>
        <div class="ml-3">
          <button
            class="btn btn-white text-primary"
            @click="$bvModal.show('mobile-filter')"
          >
            <i class="fa-solid fa-sliders"></i>
          </button>
        </div>
      </div>
    </div>
    <MobileFilter @on-filter="onFilter" />
    <Listings :smContainer="false" :listings="listings.data">
      <template v-slot:filter>
        <div class="d-none d-sm-block">
          <div
            class="listings__filters d-flex align-items-center justify-content-md-between"
          >
            <div class="d-flex align-items-center align-self-end">
              <button
                class="btn p-0 text-primary listings__filters__Btn position-relative"
                @click="onListingChange('suitable')"
                :class="{
                  'text-gray3': activeListing !== 'suitable',
                  active: activeListing === 'suitable',
                }"
              >
                {{ $t("Suitable listing") }}
              </button>
              <button
                class="btn p-0 text-primary ml-3 listings__filters__Btn position-relative"
                @click="onListingChange('nonSuitable')"
                :class="{
                  'text-gray3': activeListing !== 'nonSuitable',
                  active: activeListing === 'nonSuitable',
                }"
              >
                {{ $t("Non-suitable listing") }}
              </button>
            </div>

            <div class="d-flex">
              <div>
                <BButton
                  variant="outline-primary mr-2"
                  class=""
                  @click="$router.push('listings/map')"
                >
                  {{ $t("mapview") }}</BButton
                >
              </div>
              <label
                class="listings__filters__searchbox border mt-md-0 mb-0"
                for="search"
              >
                <input
                  class="listings__filters__searchbox__input"
                  type="text"
                  v-model="search"
                  :placeholder="$t('listingsKey.filters.searchplaceholder')"
                />
                <Magnify />
              </label>
            </div>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-12 mb-4 z-index-100 d-none d-sm-block">
          <ListingFilters @on-filter="onFilter" class="position-sticky" />
        </div>
        <div class="col-12" v-if="!listings.data.length">
          <p class="listings__empty">
            {{ $t("No listings available") }}
          </p>
        </div>
      </div>
      <template v-if="listings.data.length">
        <div class="row">
          <div
            class="col-lg-4 col-sm-6 col-12"
            v-for="listing in listings.data"
            :key="listing.id"
          >
            <Listing
              :listing="listing"
              :externalClass="['recent-listing']"
              :listingLink="
                listing.reacted ? '/listings/saved' : `/listings/${listing.slug}`
              "
            />
          </div>
        </div>
        <b-pagination
          v-model="page"
          :total-rows="listings.total"
          :per-page="limit"
          aria-controls="my-table"
          align="left"
          @change="onPageChange"
        ></b-pagination>
      </template>
    </Listings>
  </div>
</template>

<script>
import Listings from "../components/general/listing/Listings.vue";
// import SelectInput from "../components/ui/form/controls/SelectInput.vue";

import { mapActions, mapGetters } from "vuex";
import Listing from "@/components/general/listing/Listing.vue";
import ListingFilters from "../components/general/listing/ListingFilters.vue";
import Magnify from "../components/svg/magnify.vue";
import { debounce } from "@/utils/debounce.js";
import MobileFilter from "./User/Listings/MobileFilter.vue";

export default {
  components: {
    Listing,
    Listings,
    Magnify,
    ListingFilters,
    MobileFilter,
  },
  data() {
    return {
      searchTimeout: null,
      filters: {},
      search: "",
      page: 1,
      limit: 9,
      count: null,
      selectedFilter: "created_at",
      sortBy: {
        name: "sort",
        class: "bg-info listings__sort-select fw-bold",
        rules: "",
        options: [
          { text: this.$t("date"), value: "created_at" },
          { text: this.$t("price"), value: "price" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("listing", ["listings"]),
    ...mapGetters("auth", ["tenantsVerified"]),
    ...mapGetters("loadingError", ["getLoadings"]),
    activeListing() {
      return this.$route.query.type ?? "suitable";
    },
  },
  methods: {
    ...mapActions("listing", ["setUserPreferenceListings"]),
    getQueryParams(extraQuery) {
      const query = this.$route.query;

      const reqQuery = new URLSearchParams();

      const newQuery = { ...query, ...extraQuery };
      for (let key in newQuery) {
        if (newQuery[key] == null) {
          delete newQuery[key];
          continue;
        }
        reqQuery.append(key, newQuery[key]);
      }

      return { reqQuery: reqQuery.toString(), query: newQuery };
    },
    async onListingChange(type) {
      const { query } = this.getQueryParams({
        type,
        limit: this.limit,
        page: this.page,
        sort: this.selectedFilter,
      });
      this.$router.push({
        path: this.$route.fullPath,
        query,
      });
      const { total } = await this.setUserPreferenceListings({ query });

      this.count = total;
    },
    async onFilter(data) {
      this.filters = { ...data };
      // houseprice
      if (this.filters.maxRent != null && this.filters.maxRent.length) {
        this.filters.maxRent = parseInt(this.filters.maxRent.replace(/\D/g, ""));
      }
      if (this.filters.municipalities) {
        this.filters.municipalities = this.filters.municipalities.map((mun) => mun.value);
      }
      // if(this.filters.incomerequirements != null){
      //   this.filters.incomerequirements = this.filters.incomerequirements.value
      // }
      if (!this.filters.search) {
        this.filters.search = this.search;
      }
      const { query } = this.getQueryParams({
        ...this.filters,
        sort: this.selectedFilter,
        page: this.page,
        limit: this.limit,
      });

      const { total } = await this.setUserPreferenceListings({ query });

      this.count = total;
    },
    async onPageChange(page) {
      this.page = page;
      const { query } = this.getQueryParams({
        limit: this.limit,
        page: this.page,
      });

      const { total } = await this.setUserPreferenceListings({
        type: this.activeListing,
        query: query,
      });
      this.count = total;
    },
  },
  watch: {
    async selectedFilter() {
      const { reqQuery, query } = this.getQueryParams({
        sort: this.selectedFilter,
        limit: this.limit,
        page: this.page,
      });

      this.$router.push({
        path: this.$route.fullPath,
        query,
      });

      const { total } = await this.setUserPreferenceListings({
        type: this.activeListing,
        query: reqQuery,
      });
      this.count = total;
    },

    search: debounce(function (newVal) {
      this.onFilter({ ...this.filters, search: newVal });
    }, 500),
  },
  async created() {
    if (!this.tenantsVerified) {
      this.$router.push("/user/profile");
    }

    const { query } = this.getQueryParams({
      type: this.activeListing,
      limit: this.limit,
      page: this.page,
    });

    console.log("this.activeListing", this.activeListing, {
      query: query,
    });
    const { total } = await this.setUserPreferenceListings({
      type: this.activeListing,
      query: query,
    });

    this.count = total;
  },
};
</script>

<style lang="scss" scoped>
.listings {
  &__filters {
    border-bottom: 2px solid #dcdcdc;
    padding-bottom: 12px;
    margin-bottom: 25px;
    // @include sm {
    //   margin-left: -1.5rem;
    // }
    & > * {
      @include sm {
        flex-grow: 1;
        width: 100%;
      }
    }

    &-main {
      top: 10px;
      z-index: 90000;
      padding: 15px !important;
      padding-bottom: 0 !important;
      // @include lg {

      // }
      @include md {
        padding-bottom: 15px !important;
      }

      @include sm {
        padding: 20px 30px !important;
      }
    }

    &__searchbox {
      border-radius: 5px;
      padding: 10px;
      background: white;
      justify-content: space-between;
      display: flex;

      &__input {
        border: none !important;
        background: transparent;
        color: $primary;
        width: 90%;
      }

      &__input:focus-visible {
        outline: none !important;
      }
    }

    &__Btn {
      padding: 0px !important;
    }

    &__Btn.active::before {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primary;
      z-index: 10;
    }
  }

  &__sort {
    fieldset {
      margin-bottom: 0;
    }

    &-select {
      padding: 0.9375rem 3rem 0.9375rem 2rem;
    }
  }

  &__empty {
    @include font(1.325rem, #999999);

    @include xs {
      font-size: 1.25rem;
    }
  }
}
</style>
