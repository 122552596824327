<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5811 19.7624L16.5843 14.7703C17.5218 13.4765 18.0796 11.8921 18.0796 10.1765C18.0796 5.85933 14.5687 2.34839 10.2515 2.34839C5.93428 2.34839 2.42334 5.85933 2.42334 10.1765C2.42334 14.4937 5.93428 18.0046 10.2515 18.0046C11.8874 18.0046 13.4108 17.4984 14.6671 16.6359L19.6827 21.6562L21.5811 19.7624ZM4.71553 10.1718C4.71553 7.12026 7.1999 4.63589 10.2515 4.63589C13.303 4.63589 15.7874 7.12026 15.7874 10.1718C15.7874 13.2234 13.303 15.7078 10.2515 15.7078C7.1999 15.7078 4.71553 13.2234 4.71553 10.1718Z"
      fill="#70A47D"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
