<template>
  <Modal
    class="listing-mobile-filter"
    modalId="mobile-filter"
    :hide-header="true"
    :hide-footer="true"
  >
    <div class="d-flex flex-column justify-content-between bg-white min-h-90vh">
      <div>
        <div class="d-flex justify-content-between pb-3 border-bottom">
          <div class="cursor-pointer" @click="$bvModal.hide('mobile-filter')">
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div>
            <strong>
              {{ $t("Filters") }}
            </strong>
          </div>
          <div></div>
        </div>
        <div class="py-4 border-bottom">
          <button class="btn btn-primary btn-sm" v-if="filter.type == 'suitable'">
            {{ $t("Suitable") }}
          </button>
          <button class="btn btn-white btn-sm" v-else @click="filter.type = 'suitable'">
            {{ $t("Suitable") }}
          </button>
          <button class="btn btn-primary btn-sm ml-3" v-if="filter.type == 'nonsuitable'">
            {{ $t("Non-suitable") }}
          </button>
          <button
            class="btn btn-white btn-sm ml-3"
            v-else
            @click="filter.type = 'nonsuitable'"
          >
            {{ $t("Non-suitable") }}
          </button>
        </div>
        <div class="border-bottom py-3">
          <div class="pb-3">
            <strong>
              {{ $t("Price") }}
            </strong>
          </div>
          <div class="mb-4">
            <b-form-input
              v-model="filter.maxRent"
              :placeholder="$t('Set maximum price')"
            />
          </div>
        </div>
        <div class="py-4 border-bottom">
          <div class="pb-3">
            <strong>
              {{ $t("Rooms") }}
            </strong>
          </div>

          <div class="d-flex justify-content-between">
            {{ $t("Bedrooms") }}
            <div class="d-flex justify-content-between">
              <span class="icon" @click="decreament('bedrooms')"
                ><i class="fa-solid fa-minus"></i
              ></span>

              <strong class="mx-3">
                {{ filter.bedrooms }}
              </strong>

              <span class="icon" @click="increament('bedrooms')"
                ><i class="fa-solid fa-plus"></i
              ></span>
            </div>
          </div>
          <div class="mt-4 d-flex justify-content-between">
            {{ $t("Total rooms") }}
            <div class="d-flex justify-content-between">
              <span class="icon" @click="decreament('rooms')"
                ><i class="fa-solid fa-minus"></i
              ></span>

              <strong class="mx-3">
                {{ filter.rooms }}
              </strong>

              <span class="icon" @click="increament('rooms')"
                ><i class="fa-solid fa-plus"></i
              ></span>
            </div>
          </div>
        </div>

        <div class="py-4">
          <div class="pb-3">
            <strong>
              {{ $t("Municipality") }}
            </strong>
          </div>
          <Multiselect
            v-model="filter.municipalities"
            :placeholder="$t('Rotterdam, Dordrecht')"
            :options="municipality"
            label="text"
            :searchable="true"
            :allow-empty="true"
            :multiple="true"
          />
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div>
          <button class="btn btn-white" @click="filterListing">
            {{ $t("Delete all filters") }}
          </button>
        </div>
        <div>
          <button class="btn btn-primary" @click="filterListing">
            {{ $t("Filter") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import municipality from "@/constants/municipality";
import Multiselect from "vue-multiselect";
import Modal from "../../../components/ui/Modal.vue";

export default {
  components: { Multiselect, Modal },
  data() {
    return {
      filter: {
        type: "suitable",
        maxRent: "",
        bedrooms: 0,
        rooms: 0,
        municipalities: [],
      },
      municipality: municipality,
    };
  },
  mounted() {},
  methods: {
    decreament(type) {
      if (this.filter[type] != 0) {
        this.filter[type]--;
      }
    },
    increament(type) {
      this.filter[type]++;
    },
    reset() {
      this.filter = {
        type: "suitable",
        maxRent: "",
        bedrooms: 0,
        rooms: 0,
        municipality: [],
      };
      this.filterListing();
    },
    filterListing() {
      this.$emit("on-filter", this.filter);
      this.$bvModal.hide("mobile-filter");
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 11111111111111;
  top: 0;
  left: 0;
}
.icon {
  width: 25px;
  height: 25px;
  background-color: $primary;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  i {
    margin: auto;
    color: #fff;
  }
}

.modal-content {
  height: 100vh;
}
</style>
